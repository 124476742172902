import styled from '@emotion/styled';
import { colors } from '../../../utils/styleguide';
import BackgroundVideo, { BackgroundVideoProps } from '../media/BackgroundVideo';

const Section = styled.section`
  background-color: ${colors.black};
  height: 86vh;
`;

const BackgroundVideoSection = (props: Omit<BackgroundVideoProps, 'isResponsive'>) => {
  return (
    <Section>
      <BackgroundVideo {...props} isResponsive={false} />
    </Section>
  );
};

export default BackgroundVideoSection;
